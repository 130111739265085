<template>
  <b-card md="12">
    <b-row>
      <b-col md="8">
        <b-row>
          <b-col v-if="backButton" sm="1" class="p-0">
            <b-button variant="flat-primary" class="btn-icon" @click="onBack">
              <feather-icon icon="ArrowLeftIcon"/>
            </b-button>
          </b-col>
          <b-col class="p-0">
            <h2 class="p-0">
              {{ name }}
            </h2>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        md="4"
        class="text-right"
      >
        <slot/>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: 'PageHeader',
  components: {},
  props: {
    name: {
      type: String,
      required: true,
    },
    backButton: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({}),
  mounted() {
  },
  methods: {
    onBack() {
      this.$router.back()
    },
  },
}
</script>

<style scoped>

</style>
