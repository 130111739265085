import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import password from '@/router/password'
import users from '@/router/users'
import clients from '@/router/clients'
import course from '@/router/course'
import coursemodule from '@/router/coursemodule'
// :routes-imports://

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...clients,
    ...course,
    ...coursemodule,
    // :routes-unpack://
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.public) {
    return next()
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn() && to.name !== 'login') {
      router.push('/')
      return false
    }
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
