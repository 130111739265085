const password = [
  {
    path: '/perdi-minha-senha',
    name: 'perdi-minha-senha',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
  {
    path: '/recuperar-senha/:token',
    name: 'recuperar-senha',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
]

export default password
