const course = [
  {
    path: '/courses',
    name: 'courses.list',
    component: () => import('@/views/course/CourseList.vue'),
  },

  {
    path: '/courses/:uuid/modules',
    name: 'courses.modules.list',
    component: () => import('@/views/coursemodule/CourseModuleList.vue'),
  },
  {
    path: '/courses/:uuid/view',
    name: 'courses.modules.view',
    component: () => import('@/views/course/CourseView.vue'),
    meta: {
      layout: 'full',
      public: true,
    },
  },
]

export default course
